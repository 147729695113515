import React, { useMemo } from 'react'
import { Stack } from '@chakra-ui/layout'

import Container from '../../components/Container'
import Text from '../../components/Text'
// import PageModule from '../../components/PageModule'
import { responsive } from '../../components/ThemeProvider/theme'

import Layout from '../Layout'
import RenderContent from '../RenderContent'

const maxWidth = responsive('auto', '640px')

const Page = ({ data }) => {
  const postData = useMemo(() => {
    const d = data.allWpPage.nodes?.[0]
    if (!d) return null
    return {
      ...d,
      content: d.content.replace(/href="(https:\/\/redesigninfo.cc)/g, 'href="')
    }
  }, [data])
  if (!postData) return null
  return (
    <Layout title={postData.title}>
      <Container py={responsive('1.5em', '3em')} maxWidth={maxWidth} as={Stack} spacing="1.5em">
        <Text.Title as="h1">{postData.title}</Text.Title>
        <RenderContent compactImage>{postData.content}</RenderContent>
      </Container>
    </Layout>
  )
}

export default Page
