import { graphql } from 'gatsby'
import Page from '../containers/Blog/Page'

export default Page

export const query = graphql`
query PageQuery($slug: String!) {
  allWpPage(filter: { slug: { eq: $slug } }) {
    nodes {
      date(formatString: "YYYY/MM/DD")
      content
      title
    }
  }
}
`
